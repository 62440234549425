:root{
    --color-primary: #1D70A2;
    --color-white: #ffffff;
    --color-medium: #6c757d;
    --color-light: #fffeff;
    --color-dark: #000000;

    --color-medium-50: #f8f5fd;
    --color-light-50: #f0edff;
    
    // --color-lotion: #fcf9fc;
    // --color-magnolia: #f8f5fd;
    // --color-lavender: #f0edff;

    --big-title-text: 2.2rem;

    --font-weight: 600;
}

.fs-10 {
    font-size: 10px !important;
}
.fs-12 {
    font-size: 12px !important;
}
.fs-14 {
    font-size: 14px !important;
}
.fs-16 {
    font-size: 16px !important;
}
.fs-18 {
    font-size: 18px !important;
}
.fs-20 {
    font-size: 20px !important;
}
.fs-22 {
    font-size: 22px !important;
}
.fs-25 {
    font-size: 25px !important;
}
.fs-30 {
    font-size: 30px !important;
}
.fs-40 {
    font-size: 40px !important;
}
.fs-50 {
    font-size: 50px !important;
}
.fs-60 {
    font-size: 60px !important;
}
.fs-70 {
    font-size: 70px !important;
}

.fs-80 {
    font-size: 80px !important;
}

.text-dark, .text--dark {
    color: var(--color-dark) !important;
    --color: var(--color-dark) !important;
}
.bg-dark, .btn-dark {
  --background: var(--color-dark) !important;
  background: var(--color-dark) !important;
}
.text-primary, .text-primary-dark {
    color: var(--color-primary) !important;
    --color: var(--color-primary) !important;
}
.bg-primary, .btn-primary, .bg-primary-dark {
  --background: var(--color-primary) !important;
  background: var(--color-primary) !important;
}

.bold {
  font-weight: 600 !important;
}
// a {
//     color: var(--color-primary) !important;
//     --color: var(--color-primary) !important;
// }