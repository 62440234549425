/* You can add global styles to this file, and also import other style files */

@import "./assets/theme/theme.scss";

/* Angular Swiper Style */
// @import "swiper/scss";
// @import "swiper/scss/navigation";
// @import "swiper/css/free-mode";



.fab-container {
	position: fixed;
	bottom: 30px;
	right: 30px;
	z-index: 999;
	cursor: pointer;
}
/* @media only screen and (max-width: 767px) {
    .fab-container {
        bottom: 10px;
        right: 10px;
    }
} */

.fab-icon-holder {
	width: 50px;
	height: 50px;
	border-radius: 100%;
	box-shadow: 0 6px 20px rgba(0,0,0,0.2);
}

.fab-icon-holder:hover{
	opacity: 0.8;
}

.fab-icon-holder i {
	display: flex;
	align-items: center;
	justify-content: center;

	height: 100%;
	font-size: 25px;
	color: #ffffff;
}

div.fab {
	width: 60px;
	height: 60px;
}

.fab-options {
	list-style-type: none;
	margin: 0;

	position: absolute;
	bottom: 70px;
	right: 0;

	opacity: 0;

	transition: all 0.3s ease;
	transform: scale(0);
	transform-origin: 85% bottom;
}

.fab:hover + .fab-options, .fab-options {
	opacity: 1;
	transform: scale(1);
}

.fab-options li {
	display: flex;
	justify-content: flex-end;
	padding: 5px;
}

.fab-label {
	padding: 2px 5px;
	align-self: center;
	user-select: none;
	white-space: nowrap;
	border-radius: 3px;
	font-size: 14px;
	font-weight: 400;
	background: #fff;
	box-shadow: 0 6px 20px rgba(0,0,0,0.2);
	margin-right: 10px;
}



